<div class="modal {{size}}"
     [class.open]="isOpen"
     (click)="close()">
    <div class="modal-content"
         (click)="clickInModal($event)">
        <ng-content></ng-content>
        <div class="modal-close"
             (click)="close()">
            <app-icon name="close" />
        </div>
    </div>
</div>
