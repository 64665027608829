import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    inject,
} from '@angular/core';
import { IconComponent } from '@shared/ui-elements/icon/icon.component';

export type Sizes = 'xl' | 'l' | 'm' | 's';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class ModalComponent implements OnInit, OnDestroy {
    private renderer = inject(Renderer2);

    @Input() size?: Sizes;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    public isOpen: boolean = false;
    private unlistener!: () => void;

    ngOnInit(): void {
        this.unlistener = this.renderer.listen(document, 'keydown', (event) => {
            if (event.code === 'Escape') {
                this.close();
            }
        });
    }

    ngOnDestroy(): void {
        this.unlistener();
    }

    public open() {
        this.isOpen = true;
        document.body.style.overflow = 'hidden';
    }

    public close() {
        this.isOpen = false;
        document.body.style.overflow = 'auto';

        this.afterClose();
    }

    private afterClose(): void {
        this.onClose.emit(true);
    }

    public clickInModal(e: Event): void {
        e.stopPropagation();
    }
}
